<template>
    <div>
        <section>
            <div id="banner" class="carousel slide carousel-fade" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img
                            class="img-fluid w-100 banner-img"
                            src="/assets/img/complaints.png"
                            style="height: 400px; object-fit: cover;">
                        <div
                            :class="[
                                'carousel-caption d-flex h-100 align-items-center',
                                'justify-content-center m-0 p-0 t-0'
                            ]"
                        ></div>
                    </div>
                </div>
            </div>
        </section>
        <article>
            <div class="container py-3">
                <h1 class="h3 text-center pb-4">Canal de Ética</h1>
                <div class="pb-3">
                    <h5 class="pb-3">Página inicial</h5>
                    <p>
                        Este é um canal exclusivo da Mentes Notáveis para comunicação segura –
                        identificada ou anônima – de condutas que configurem descumprimento dos
                        dispositivos do Código de Ética e Conduta, das Políticas de <em>Compliance</em>
                        da Mentes Notáveis e/ou dos dispositivos legais e regulatórios.
                    </p>
                    <p>
                        As informações aqui registradas serão recebidas por uma empresa
                        independente e especializada, assegurando o sigilo absoluto e o tratamento adequado de cada situação.
                    </p>
                    <p>
                        A Mentes Notáveis garante que todas as informações serão recebidas e
                        tratadas de forma profissional e ética, sem conflito de interesses, e que não haverá qualquer retaliação a informações apresentadas de boa-fé.
                    </p>
                    <p>
                        Este canal tem como objetivo receber denúncias de condutas consideradas
                        antiéticas ou que violem os princípios e padrões de conduta ou a legislação.
                    </p>
                    <p>
                        Para esclarecimentos, reclamações, elogios, sugestões ou outras
                        informações sobre os produtos e serviços disponibilizados pela Mentes Notáveis, por favor, acesse:
                        <a href="https://www.mentesnotaveis.com.br" target="_blank">https://www.mentesnotaveis.com.br</a>.
                    </p>
                </div>
            </div>
            <div class="container py-3">
                <div class="pb-3">
                    <a
                        href="/create"
                        class="btn btn-primary"
                    >
                        Realizar Relato
                    </a>
                    <a
                        href="/consult"
                        class="btn btn-primary"
                    >
                        Acompanhar Relato
                    </a>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default  {

    }
</script>
