<template>
    <div>
        <section>
            <div id="banner" class="carousel slide carousel-fade" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img
                            class="img-fluid w-100 banner-img"
                            src="/assets/img/complaints.png"
                            style="height: 400px; object-fit: cover;"
                        >
                        <div
                            :class="[
                                'carousel-caption d-flex h-100 align-items-center',
                                'justify-content-center m-0 p-0 t-0'
                            ]"
                        ></div>
                    </div>
                </div>
            </div>
        </section>
        <article>
            <div class="container py-3 user-select-none">
                <h1 class="h3 text-center pb-4">Canal de Ética</h1>
                <div v-if="termsEnabled" class="pb-3">
                    <h5 class="pb-3">Realizar relato</h5>
                    <p>
                        As informações aqui registradas serão recebidas por uma empresa
                        independente e especializada, assegurando sigilo absoluto e o tratamento
                        adequado de cada situação pela Mentes Notáveis, sem conflito de interesses.
                    </p>
                    <p>
                        A veracidade das informações providas é responsabilidade do relator.
                        <br />
                        Todas as informações serão verificadas durante o processo de averiguação,
                        e as ações decorrentes serão tomadas a critério exclusivo da Mentes Notáveis.
                    </p>
                    <p>Proteção de dados</p>
                    <p>
                        Todas as informações aqui registradas serão tratadas de forma
                        confidencial pela Mentes Notáveis e pela empresa independente e
                        especializada na captação e no tratamento de denúncias.<br />

                        A captação dessas informações tem por finalidade a apuração de possíveis
                        condutas que configurem descumprimento dos dispositivos do Código de
                        Ética e Conduta, das Políticas de <em>Compliance</em> da Mentes Notáveis e/ou
                        dos dispositivos legais e regulatórios.<br />

                        Todos os relatos serão armazenados por tempo indeterminado para a
                        realização do processo de apuração e deliberação sobre o caso,
                        observando-se as exigências legais específicas. Além disso, informações
                        consolidadas serão utilizadas para a geração de estatísticas da operação,
                        mas nenhum nome envolvido ou dado pessoal será exposto.<br />

                        Eventuais dados pessoais informados serão tratados conforme as normativas
                        estabelecidas pela legislação vigente no que diz respeito à proteção de
                        dados pessoais e essas normas serão seguidas pela Aliant no processo de
                        captação e pela Mentes Notáveis no processo de apuração dos relatos aqui
                        registrados.<br />

                        Ao clicar em "Concordo", você indica ciência e concordância com o
                        fornecimento de informações que serão única e exclusivamente utilizadas
                        para essa finalidade.
                    </p>
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                id="input-terms"
                                class="form-check-input"
                                type="checkbox"
                                name="terms"
                                @change="onAcceptTerms"
                            >
                            <label class="form-check-label font-weight-bold" for="input-terms">
                                Declaro que li e compreendi as informações acima e desejo
                                prosseguir com a manifestação.
                            </label>
                        </div>
                    </div>
                    <div v-if="termsButtonsEnabled" class="pb-3">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="onConfirmTerms"
                        >
                            Eu aceito os termos acima
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="onResufeTerms"
                        >
                            Eu não aceito os termos acima
                        </button>
                    </div>
                </div>
                <div v-if="identificationEnabled" class="pb-3">
                    <h5 class="pb-3">Realizar relato</h5>
                    <p>
                        Você escolhe se prefere fazer o registro identificado ou anônimo.
                    </p>
                    <p>
                        A opção identificada é voltada para os casos em que o relator se
                        disponibiliza a ser contatado para esclarecimento de possíveis dúvidas
                        sobre o relato fornecido.<br />
                        Relatos com identificação são muito importantes, pois podem fazer com que
                        a apuração seja mais efetiva.
                    </p>
                    <div class="pb-3">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="setFormType('identified')"
                        >
                            Sim
                        </button>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="setFormType('anonymous')"
                        >
                            Não
                        </button>
                    </div>
                    <div class="pb-3">
                        <form v-if="formType == 'identified'" ref="identifiedForm">
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="inputName">
                                        <span class="required">*</span> Nome
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="name"
                                        id="inputName"
                                        placeholder="Nome"
                                        maxlength="150"
                                        required
                                        v-model="data.person.name"
                                    >
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="inputFunction">
                                        <span class="required">*</span>
                                        Função ou sua relação com a empresa
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="relationship"
                                        id="inputFunction"
                                        placeholder="Função ou sua relação com a empresa"
                                        maxlength="150"
                                        required
                                        v-model="data.person.relationship"
                                    >
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="inputEmail">
                                        <span class="required">*</span> E-mail
                                    </label>
                                    <input
                                        type="email"
                                        class="form-control"
                                        name="email"
                                        id="inputEmail"
                                        placeholder="E-mail"
                                        maxlength="150"
                                        required
                                        v-model="data.person.email"
                                    >
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <label for="inputPhone">
                                        <span class="required">*</span> Telefone
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="phone"
                                        id="inputPhone"
                                        placeholder="Telefone"
                                        maxlength="15"
                                        required
                                        v-mask="'(##) #####-####'"
                                        v-model="data.person.phone"
                                    >
                                </div>
                                <div class="pb-3">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="writeComplaint"
                                    >
                                        Continuar
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form v-if="formType == 'anonymous'" ref="anonymousForm">
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <label for="inputEmail">
                                        E-mail
                                    </label>
                                    <input
                                        type="email"
                                        class="form-control"
                                        name="email"
                                        id="inputEmail"
                                        placeholder="E-mail"
                                        maxlength="150"
                                        v-model="data.person.email"
                                    >
                                </div>
                                <p>
                                    "E-mail anônimo opcional. Para a garantia do anonimato,
                                    recomendamos que você insira um endereço de e-mail sem nenhum
                                    elemento identificativo do seu nome ou dado pessoal."
                                </p>
                                <div class="pb-3">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="writeComplaint"
                                    >
                                        Continuar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div v-if="formComplaintEnabled" class="pb-3">
                    <h5 class="pb-3">Realizar relato</h5>
                    <p>
                        Por favor, descreva a situação em que houve descumprimento dos
                        dispositivos do Código de Ética e Conduta, das Políticas de
                        <em>Compliance</em> da Mentes Notáveis e/ou dos dispositivos legais e
                        regulatórios.
                    </p>
                    <p>
                        É importante que seu relato seja completo e detalhado, pois isso tornará
                        a apuração da denúncia mais precisa e rápida. Não se esqueça de incluir
                        na descrição:
                    </p>
                    <ul>
                        <li>O quê (descrição da situação).</li>
                        <li>Quem (nome das pessoas envolvidas, inclusive testemunhas).</li>
                        <li>Quando (data em que aconteceu, acontece ou acontecerá a situação).</li>
                        <li>Onde (local do ocorrido).</li>
                        <li>Por quê (a causa ou motivo).</li>
                        <li>Quanto (se for possível medir).</li>
                        <li>Provas (se elas existem e onde podem ser encontradas).</li>

                    </ul>
                    <p>
                        Para acompanhar o andamento de seu relato, você receberá um número de
                        protocolo que será fornecido após o registro do relato.
                    </p>
                    <p>
                        Agradecemos sua iniciativa e confiança.
                    </p>
                    <h5 class="pb-3">Dados do incidente</h5>
                    <form ref="formComplaint">
                        <div class="form-row">
                            <div class="form-group col-md-4">
                                <label for="inputType">Tipo da ocorrência</label>
                                <select
                                    id="inputType"
                                    name="occurrence_id"
                                    class="form-control"
                                    title="Tipo da ocorrência"
                                    v-model="data.occurrence.occurrence_id"
                                    required
                                    :disabled="sending"
                                >
                                    <option
                                        v-for="occurrence in occurrences"
                                        :value="occurrence.id"
                                    >
                                        {{ occurrence.title }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group col-12">
                                <label for="inputDescription">Descrição</label>
                                <textarea
                                    id="inputDescription"
                                    name="description"
                                    placeholder="Descrição"
                                    class="form-control"
                                    rows="3"
                                    maxlength="500"
                                    v-model="data.occurrence.description"
                                    required
                                    @keyup="onInputText"
                                    :disabled="sending"
                                ></textarea>
                            </div>
                            <p>
                                Se você quiser anexar arquivos como fotos e documentos,
                                adicione-os aqui. O tamanho máximo do conjunto de arquivos é de
                                100 MB.
                            </p>
                            <div class="form-group col-12">
                                <label for="inputFile">Anexar arquivo</label>
                                <input
                                    type="file"
                                    placeholder="Arquivo"
                                    id="inputFile"
                                    name="files"
                                    multiple
                                    class="form-control-file"
                                    @change="loadFile"
                                    :disabled="sending"
                                >
                            </div>
                            <div class="my-3">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="send"
                                    :disabled="sending"
                                >
                                    <span
                                        v-if="sending"
                                        class="spinner-border spinner-border-sm loading"
                                        aria-hidden="true"
                                    ></span>
                                    <span v-if="!sending" class="text">Gravar</span>
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    @click="cancel"
                                    :disabled="sending"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div v-if="confirmedEnabled" class="pb-3">
                    <h5 class="pb-3">Realizar relato</h5>
                    <p>
                        Seu relato foi registrado com sucesso e será devidamente analisado pela
                        Mentes Notáveis. Por favor, anote o protocolo desse relato:
                    </p>
                    <h5
                        class="font-weight-bold text-center my-3"
                        @click="copyProtocol"
                        data-toggle="tooltip"
                        title="Clique para copiar o protocolo"
                    >
                        {{ protocol }}
                    </h5>
                    <p>
                        Lembre-se de salvar o protocolo para poder acompanhar ativamente o
                        tratamento de seu relato.<br />
                        Para acompanhar a análise de seu relato, basta clicar na aba "Acompanhar
                        relato" e informar o número do protocolo.
                    </p>
                    <div class="pb-3">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="cancel"
                        >
                            Retornar à página inicial
                        </button>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                termsEnabled: true,
                identificationEnabled: false,
                termsButtonsEnabled: false,
                formComplaintEnabled: false,
                confirmedEnabled: false,
                formType: '',
                sending: false,
                data: {
                    type: '',
                    person: {
                        name: '',
                        relationship: '',
                        email: '',
                        phone: '',
                    },
                    occurrence: {
                        occurrence_id: '',
                        description: '',
                        files: []
                    }
                },
                protocol: ''
            };
        },
        props: {
            occurrences: Array,
        },
        methods: {
            onAcceptTerms(e) {
                this.termsButtonsEnabled = $(e.target).is(':checked');
            },
            onConfirmTerms() {
                this.termsEnabled = false;
                this.identificationEnabled = true;
            },
            onResufeTerms() {
                Swal.fire({
                    type: 'info',
                    title: 'Termo rejeitado!'
                })
                .then(() => {
                    window.location.href = '/';
                });
            },
            setFormType(type) {
                this.formType = type;
                this.data.person.name = '';
                this.data.person.relationship = '';
                this.data.person.email = '';
                this.data.person.phone = '';
            },
            writeComplaint() {
                let form = null;

                switch (this.formType) {
                    case 'identified':
                        form = this.$refs.identifiedForm;
                        break;
                    case 'anonymous':
                        form = this.$refs.anonymousForm;
                        break;
                }

                let validate = this.validateForm(form);

                if (validate != '') {
                    Flash('error', validate);
                } else {
                    this.identificationEnabled = false;
                    this.formComplaintEnabled = true;
                }
            },
            loadFile(e) {
                for (let file of e.target.files) {
                    this.data.occurrence.files.push(file);
                }
			},
            onInputText(e) {
                const EL = $(e.target);
                const PLACEHOLDER = EL.attr('placeholder');
                const MAXLENGTH = EL.attr('maxlength');
                const N_CHARS = EL.val().length;

                let label = EL.closest('div').find('label');
                label.html(`${PLACEHOLDER} (${N_CHARS}/${MAXLENGTH})`);
            },
            send() {
                this.sending = true;
                let form = this.$refs.formComplaint;
                let validate = this.validateForm(form);

                if (validate != '') {
                    Flash('error', validate);
                    this.sending = false;
                } else {
                    this.data.type = this.formType;

                    let data = new FormData();

					for (let [key, value] of Object.entries(this.data)) {
                        if (value instanceof Object) {
                            for (let [k, v] of Object.entries(value)) {
                                if (v instanceof Array) {
                                    for (let file of v) {
                                        data.append(`${key}[${k}][]`, file);
                                    }
                                } else {
                                    data.append(`${key}[${k}]`, v);
                                }
                            }
                        } else {
                            data.append(key, value);
                        }
					}

                    axios.post('/web/complaints', data)
                        .then(async res => {
                            this.formComplaintEnabled = false;
                            this.confirmedEnabled = true;
                            this.protocol = res.data;

                            await Help.delay(1000);

                            window.config.loadTooltip();
                        })
                        .catch(err => {
                            Flash(
                                'error',
                                'Não foi possível enviar o relato, tente novamente mais tarde!'
                            );
                            this.sending = false;
                        })
                }
            },
            cancel() {
                window.location.href = '/';
            },
            copyProtocol(e) {
                try {
                    navigator.clipboard.writeText($(e.target).text().trim());
                } catch (error) {}
            }
        }
    }
</script>
