<template>
    <header>
        <nav
            ref="nav"
            class="navbar navbar-expand-lg navbar-light fixed-top navbar-expand-md"
        >
            <div class="container">
                <a class="navbar-brand" href="/">
                    <img src="/assets/img/logo.png" alt="logo Mentes Notáveis">
                </a>
            </div>
        </nav>
    </header>
</template>

<script>
    export default {
        data() {
            return {
                nav: null
            };
        },
        mounted() {
            this.nav = $(this.$refs.nav);

            $(document).on('scroll', this.onScrolling);
            this.onScrolling();
        },
        methods: {
            onScrolling() {
                const SCROLL_TOP = $(document).scrollTop();
                const NAV_H = this.nav.height();

                this.nav.toggleClass('scrolled', SCROLL_TOP > NAV_H);
		        this.nav.toggleClass('shrink', SCROLL_TOP > NAV_H);
            }
        }
    }
</script>
