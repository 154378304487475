<template>
    <div>
        <footer class="text-muted">
            <div class="container">
                <hr />
                <div class="row d-flex">
                    <div class="col-sm-12 col-md-6 mb-3">
                        <span class="float-left text-center">
                            © 2012/{{ currentYear }} Mentes Notáveis. Todos os direitos reservados.
                        </span>
                    </div>
                    <div class="col-sm-12 col-md-6 mb-3">
                        <span class="float-right text-center">
                            Mentes Notáveis Sis. e Cons. de Inf. LTDA - 32.131.669/0001-09
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                currentYear: new Date().getFullYear()
            }
        },
        methods: {
            backToTop() {
                $('html, body').animate({
                    scrollTop: 0
                }, 700);
            }
        }
    }
</script>
